import React from 'react';
import { AppBar, Tabs, Tab, useTheme, Button, Toolbar, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

const TabsComponent = ({ selectedTab, handleTabChange, handleSave }) => {
  const theme = useTheme();

  return (
    <AppBar position="static">
      <Toolbar sx={{
        backgroundColor: theme.palette.background.alt,
        color: theme.palette.secondary[200],
        flex: 1,
      }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[200],
            flex: 1,
            '.Mui-selected': {
              color: theme.palette.secondary.contrastText,
              backgroundColor: '#FFE3A3',
            }
          }}
        >
          <Tab label="Geral" />
          <Tab label="SEO" />
          <Tab label="Redes Sociais" />
        </Tabs>
        <Box sx={{ display: 'flex', alignItems: 'center', color: "primary" }}>
          <Link to="https://thiagorodrigo.com.br/" target="_blank" >
            <Button color="primary">
              <HomeIcon fontSize="large" />
            </Button>
          </Link>
          <Button variant="contained" color="primary" onClick={handleSave}>
            <SaveIcon /> Salvar
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TabsComponent;
