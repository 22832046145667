import React from "react";
import {
  useDeleteSpecialMutation,
  useGetSpecialsQuery,
  useUpdateSpecialMutation,
} from "state/api";
import { getColumns } from "./columns";

import DataGridEditableRow from "components/DataGridEditableRow";

export const Specials = () => {

  return (
    <DataGridEditableRow
      useGet={useGetSpecialsQuery}
      useUpdate={useUpdateSpecialMutation}
      useDelete={useDeleteSpecialMutation}
      getColumns={getColumns}
      createLink="/specials/create"
    />
  );
};

export default React.memo(Specials);
