import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useRef, useState } from "react";
import VisuallyHiddenInput from './VisuallyHiddenInput';
import FormLabel from '@mui/material/FormLabel';

export default function FormikTinyMCE({ id, label, formik }) {
  const editorRef = useRef(null);
  const [focused, setFocused] = useState(false);

  const handleChange = useCallback((newText) => {
    formik.setFieldValue(id, newText);
  }, [id])

  return (
    <>
      <FormLabel color="secondary" focused={focused} sx={{marginLeft: 1}}>{label}</FormLabel>
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(_evt, editor) => editorRef.current = editor}
        value={formik.values[id]}
        onEditorChange={handleChange}
        onFocusIn={() => setFocused(true)}
        onFocusOut={() => setFocused(false)}
        init={{
          plugins: TyneMCEPlugins,
          toolbar: (
            "image link | undo redo | fontfamily fontsize  styles | forecolor bold italic|" +
            "alignleft aligncenter alignright alignjustify | outdent indent | code"
          ),
          promotion: false,
          elementpath: false,
          branding: false,
          resize: false,
          browser_spellcheck: true,
          // spellchecker_language: 'pt_BR' //TODO: Fix tinymcespellchecker HTML <DOCTYPE> error,
          // language: "pt_BR" // Same as above
          license_key: "gpl",
        }}
      />
      <VisuallyHiddenInput type="text" id={id} name={id} defaultValue={formik.values[id]}/>
    </>
  )
}


const TyneMCEPlugins = [
  "advlist",
  "anchor",
  "autolink",
  "charmap",
  "code",
  "emoticons",
  "help",
  "image",
  "link",
  "lists",
  "media",
  "preview",
  "searchreplace",
  "table",
  // "tinymcespellchecker",
  "visualblocks",
  "wordcount",
];
