import React from 'react';
import { Stack, Button } from '@mui/material';
import CustomTextField from 'components/CustomTextField';
import CustomSelect from 'components/CustomSelect';
import CustomMultiSelect from 'components/CustomMultiSelect';


const FormComponent = ({ formik, categoryOptions, specialOptions }) => {

  const handleSave = () => {
  };

  const handleSaveAndContinue = () => {
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <CustomTextField
          label="Nome da Loja"
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <CustomTextField
          label="Slug (automático)"
          id="slug"
          name="slug"
          value={formik.values.slug}
          onChange={formik.handleChange}
          disabled={true}
        />
        <CustomTextField
          label="URL da Fonte"
          id="source_url"
          name="source_url"
          value={formik.values.source_url}
          onChange={formik.handleChange}
          error={formik.touched.source_url && formik.errors.source_url}
          helperText={formik.touched.source_url && formik.errors.source_url}
        />
        <CustomTextField
          label="URL Rastreada"
          id="tracked_url"
          name="tracked_url"
          value={formik.values.tracked_url}
          onChange={formik.handleChange}
          error={formik.touched.tracked_url && formik.errors.tracked_url}
          helperText={formik.touched.tracked_url && formik.errors.tracked_url}
        />
        <CustomSelect
          label="Categoria Principal"
          id="category"
          name="category"
          value={formik.values.category}
          onChange={formik.handleChange}
          options={categoryOptions}
        />
        <CustomTextField
          label="URL Original"
          id="original_url"
          name="original_url"
          value={formik.values.original_url}
          onChange={formik.handleChange}
        />
        <CustomTextField
          label="URL Trackeada Principal"
          id="main_tracked_url"
          name="main_tracked_url"
          value={formik.values.main_tracked_url}
          onChange={formik.handleChange}
        />
        <CustomTextField
          label="URL Trackeada para Android"
          id="android_tracked_url"
          name="android_tracked_url"
          value={formik.values.android_tracked_url}
          onChange={formik.handleChange}
        />
        <CustomTextField
          label="URL Trackeada para iOS"
          id="ios_tracked_url"
          name="ios_tracked_url"
          value={formik.values.ios_tracked_url}
          onChange={formik.handleChange}
        />
        <CustomTextField
          label="Tracking"
          id="tracking"
          name="tracking"
          value={formik.values.tracking}
          onChange={formik.handleChange}
        />
        <CustomMultiSelect
          label="Domínios Autorizados"
          id="authorized_domains"
          name="authorized_domains"
          value={formik.values.authorized_domains}
          onChange={formik.handleChange}
        />

        <CustomSelect
          label="Categorias Adicionais"
          id="additional_categories"
          name="additional_categories"
          value={formik.values.additional_categories}
          onChange={formik.handleChange}
          options={categoryOptions}
        />
        <CustomSelect
          label="Listas de Categorias"
          id="category_lists"
          name="category_lists"
          value={formik.values.category_lists}
          onChange={formik.handleChange}
          options={categoryOptions}
        />
        <CustomSelect
          label="Especiais"
          id="specials"
          name="specials"
          value={formik.values.specials}
          onChange={formik.handleChange}
          options={specialOptions}
        />
        <CustomSelect
          label="Listas de Especiais"
          id="special_lists"
          name="special_lists"
          value={formik.values.special_lists}
          onChange={formik.handleChange}
          options={specialOptions}
        />

        {/* Adicionar MenuItems aqui */}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveAndContinue}>
          Salvar e Continuar
        </Button>
      </Stack>
    </form>
  );
};

export default FormComponent;
