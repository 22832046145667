export const specials_endpoints = {
  endpoints: (build) => ({
    getSpecials: build.query({
      providesTags: ["Specials"],
      query: (params) => ({
        url: "specials/",
        method: "GET",
        params: {
          page: params?.page,
          page_size: params?.pageSize,
          ordering: params?.sort,
          search: params?.search,
          fields: params?.fields,
        },
      }),
    }),
    getSpecial: build.query({
      query: (id) => ({
        url: `specials/${id}`,
        method: "GET",
      }),
      providesTags: ["Specials"],
    }),
    createSpecial: build.mutation({
      query: (body) => ({
        url: "specials/",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["Specials"],
    }),
    updateSpecial: build.mutation({
      query: ({id, body, formData}) => {
        return {
        url: `specials/${id}/`,
        method: "PUT",
        body: body || formData,
        formData: Boolean(formData),
      }},
      invalidatesTags: [{ type: "Specials", id: "LIST" }],
    }),
    deleteSpecial: build.mutation({
      query: (id) => ({
        url: `specials/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Specials", id: "LIST" }],
    }),
  }),
};
