import { formatErrorMessages } from './errorUtils';

export const handleDelete = async (idToDelete, deleteMutation, refetch, closeDialog) => {
  if (idToDelete !== null) {
    try {
      await deleteMutation(idToDelete);
      refetch();
    } catch (error) {
      console.error("Erro ao deletar:", error);
    }
    closeDialog();
  }
};

  export const handleEditCommit = async (newRow, updateMutation, showErrorDialog) => {
    console.log("Dados recebidos para atualização:", newRow);

    try {
      const updatedRow = await updateMutation({
        id: newRow.id,
        ...newRow
      }).unwrap();

      console.log("Resposta da atualização:", updatedRow);
      return updatedRow;
    } catch (error) {
      console.error("Erro ao atualizar:", error);

      let errorMessage = "Ocorreu um erro ao tentar atualizar. Por favor, tente novamente.";

      if (error.data && typeof error.data === 'object') {
        errorMessage = formatErrorMessages(error.data);
      } else if (error.error && typeof error.error === 'string') {
        errorMessage = error.error;
      }

      showErrorDialog(errorMessage);
      throw error;
    }
  };
