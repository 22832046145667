import React from 'react';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const DataGridCustomTableClientSide = ({
  data,
  isLoading,
  columns,
  handleEditCommit,
  page,
  setPage,
  pageSize,
  setPageSize,
  rowCount,
}) => {
  const theme = useTheme();

  return (
    <Box
      height="80vh"
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: 'none',
          fontWeight: 'bold',
          fontSize: '1.1rem',
        },
        '& .MuiDataGrid-columnHeader': {
          height: '100px !important',
          minHeight: '100px !important',
          maxHeight: '100px !important',
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: '#FFFFFF',
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: 'none',
        },
      }}
    >
      <DataGrid
        loading={isLoading || !data}
        getRowId={(row) => row.id}
        rows={data || []}
        columns={columns}
        processRowUpdate={handleEditCommit}
        experimentalFeatures={{ newEditingApi: true }}
        rowCount={rowCount}
        checkboxSelection
        pagination
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          setPage(0);
        }}
        rowsPerPageOptions={[10, 20, 50, 100]}
        paginationMode="client"
        headerHeight={100}
      />
    </Box>
  );
};

export default DataGridCustomTableClientSide;
