import React from 'react';
import { Container,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  useTheme
} from '@mui/material';


const CodeFormComponent = () => {
  const theme = useTheme();

  return (
      <Container
        maxWidth="md"
        style={{
          height: '40vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
          }}>
        <Paper
          elevation={3}
          style={{
            padding: '50px',
            background: theme.palette.background.alt
            }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom>
            Novo Código
          </Typography>
          <Grid
            container
            spacing={2}>
            <Grid item xs={15}>
              <Typography variant="subtitle1" sx={{ lineHeight: 'normal' }}>
                Código
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: false,
                }}/>
            </Grid>
            <Grid item xs={15}>
              <Typography variant="subtitle1" sx={{ lineHeight: 'normal' }}>
                Descrição
              </Typography>
              <TextField
                variant="outlined"
                fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Probabilidade"
                variant="outlined"
                fullWidth
                type="number"/>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '10px' }}>
            Enviar
          </Button>
        </Paper>
      </Container>

  );
};

export default CodeFormComponent;
