import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Menu } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from '@mui/material/Box';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setMode } from "state";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen, isMobile }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [userMenu, setUserMenu] = useState(null);
  const openUserMenu = (event) => setUserMenu(event.currentTarget);
  const closeUserMenu = () => setUserMenu(null);

  const menu = !isMobile ? "" : (
    <Tooltip title="Abrir menu lateral">
      <IconButton
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        color="inherit"
        aria-label="Abrir menu"
        edge="start"
      >
        <MenuIcon />
      </IconButton>
    </Tooltip>
  );

  const logo = isMobile ? "" : <Avatar src="" variant="rounded" sx={{mx: "10px"}}/>;

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          {menu}
          {logo}
          <Typography variant="h6" noWrap component="div">
            Thiago Rodrigo
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Preferências">
            <IconButton onClick={openUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={userMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(userMenu)}
        onClose={closeUserMenu}
      >
        <MenuItem onClick={() => dispatch(setMode())}>
          {theme.palette.mode === "dark" ? (
            <>
              <ListItemIcon>
                <LightModeOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Claro</ListItemText>
            </>
          ) : (
            <>
              <ListItemIcon>
                <DarkModeOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Escuro</ListItemText>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={closeUserMenu} component={Link} to="/logout">
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Navbar;
