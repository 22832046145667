import React from "react";
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from "state/api";
import { getColumns } from "./columns";

import DataGridEditableRow from "components/DataGridEditableRow";

export const Categories = () => {

  return (
    <DataGridEditableRow
      useGet={useGetCategoriesQuery}
      useUpdate={useUpdateCategoryMutation}
      useDelete={useDeleteCategoryMutation}
      getColumns={getColumns}
      createLink="/categories/create"
    />
  );
};

export default React.memo(Categories);
