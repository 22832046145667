import React from 'react';
import { AppBar,
  Toolbar,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/List";
import ImportTextIcon from "@mui/icons-material/Description";
import ImportImageIcon from "@mui/icons-material/Image";
import ClearCacheIcon from "@mui/icons-material/Clear";
import { Link } from 'react-router-dom';


const CustomAppBar = ({exportToCSV}) => {
  const theme = useTheme();

  return (
    <AppBar position="relative">
    <Toolbar sx={{
        backgroundColor: theme.palette.background.alt,
        color: theme.palette.secondary[200],
        flex: 1,
        }}>
        <Link to="/add-coupons" style={{ textDecoration: 'none', color: 'inherit' }}>
          <IconButton color="inherit" aria-label="Adicionar Cupom">
            <AddIcon />
          </IconButton>
          <Typography variant="button" color="inherit">
            Adicionar Cupom
          </Typography>
        </Link>

        <IconButton color="inherit" aria-label="Exportar como CSV" onClick={() => exportToCSV()}>
          <DownloadIcon />
        </IconButton>
        <Typography variant="button" color="inherit">
          Exportar como CSV
        </Typography>

        <IconButton color="inherit" aria-label="Importar (TXT)">
          <ImportTextIcon />
        </IconButton>
        <Typography variant="button" color="inherit">
          Importar (TXT)
        </Typography>

        <IconButton color="inherit" aria-label="Importar (imagem)">
          <ImportImageIcon />
        </IconButton>
        <Typography variant="button" color="inherit">
          Importar (imagem)
        </Typography>

        <IconButton color="inherit" aria-label="Limpar Cache">
          <ClearCacheIcon />
        </IconButton>
        <Typography variant="button" color="inherit">
          Limpar Cache
        </Typography>

    </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
