import React from 'react';
import { AppBar, Tabs, Tab, useTheme, Button, Toolbar, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

const TabsComponent = ({ selectedTab, handleTabChange, handleSave }) => {
  const theme = useTheme();

  // Função para aplicar estilo condicional às abas com base na aba selecionada
  const tabStyle = (index) => ({
    backgroundColor: selectedTab === index ? theme.palette.secondary.main : theme.palette.background.alt,
    color: selectedTab === index ? theme.palette.secondary.contrastText : theme.palette.secondary[200],
  });

  return (
    <AppBar position="static">
      <Toolbar sx={{
        backgroundColor: theme.palette.background.alt,
        color: theme.palette.secondary[200],
        flex: 1,
      }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[200],
            flex: 1,
          }}
          indicatorColor="primary"
        >
          <Tab label="Geral" sx={tabStyle(0)} />
          <Tab label="Códigos" sx={tabStyle(1)} />
          <Tab label="URLs" sx={tabStyle(2)} />
        </Tabs>
        <Box sx={{ display: 'flex', alignItems: 'center', color: "primary" }}>
          <Link to="https://thiagorodrigo.com.br/" target="_blank" >
            <Button color="primary">
              <HomeIcon fontSize="large" />
            </Button>
          </Link>
          <Button variant="contained" color="primary" onClick={handleSave}>
            <SaveIcon /> Salvar
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TabsComponent;
