if (process.env.NODE_ENV === 'development') {
    module.exports = {
        api_base_url: "http://localhost:8080",
        drawerWidth: 240,
    };
} else {
    module.exports = {
        api_base_url: "https://descontogratis.com.br",
        drawerWidth: 240,
    };
}
