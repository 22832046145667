import React from "react";

import FormikAutocomplete from 'components/FormikAutocomplete';
import slugify from 'react-slugify';


function AlternativeSlugs({name, formik, excluded = [], ...props}) {

  const handleChange = (event, newValues, actionType) => {
    if (actionType === "createOption") {
      const newOption = newValues.pop();
      formik.setFieldValue(name, [...newValues, {id: String(Math.random()), name: slugify(newOption)}])
    } else {
      formik.setFieldValue(name, newValues)
    }
  }

  return (
    <FormikAutocomplete
      name={name}
      options={formik.values[name] || []}
      value={formik.values[name]}
      onChange ={handleChange}
      freeSolo
      {...props}
    />
  );
};

export default AlternativeSlugs;
