export const slugs_endpoints = {
  endpoints: (build) => ({
    getSlugs: build.query({
      providesTags: ["Slugs"],
      query: (params) => ({
        url: "slugs/",
        method: "GET",
        params: {
          page: params?.page,
          page_size: params?.pageSize,
          ordering: params?.sort,
          search: params?.search,
        },
      })
    }),
    getSlugNamesById: build.query({
      providesTags: ["Slugs"],
      query: () => ({
        url: "slugs-by-id",
        method: "GET",
      }),
      transformResponse: responseData => {
        return responseData.reduce((a, v) => ({ ...a, [v.id]: v}), {})
      }
    }),
    getSlug: build.query({
      query: (id) => ({
        url: `slugs/${id}`,
        method: "GET",
      }),
      providesTags: ["Slugs"],
    }),
    createSlug: build.mutation({
      query: (body) => ({
        url: "slugs/",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["Slugs"],
    }),
    updateSlug: build.mutation({
      query: ({id, formData}) => {
        return {
        url: `slugs/${id}/`,
        method: "PATCH",
        body: formData,
        formData: true,
      }},
      invalidatesTags: [{ type: "Slugs", id: "LIST" }],
    }),
    deleteSlug: build.mutation({
      query: (id) => ({
        url: `slugs/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Slugs", id: "LIST" }],
    }),
  }),
};
