import renderCellExpand from "components/GridCellExpand";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RestoreIcon from "@mui/icons-material/Restore";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Link as RouterLink } from "react-router-dom";

export default function getColumns(trackingMethods) {
  return function (apiRef, unsavedChangesRef, setHasUnsavedRows) {
    return [
      {
        field: "name",
        headerName: "Nome",
        flex: 0.5,
        renderCell: renderCellExpand,
        editable: true,
      },
      {
        field: "tracking_method",
        headerName: "Tracking Método",
        flex: 0.5,
        editable: true,
        type: "singleSelect",
        valueOptions: trackingMethods
      },
      {
        field: "template",
        headerName: "Template",
        flex: 1,
        editable: true,
      },
      {
        field: "params",
        headerName: "Parâmetros",
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false,
        valueFormatter: (value) => {
          return JSON.stringify(value);
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        getActions: ({ id, row }) => {
          return [
            <Tooltip title="Editar">
              <GridActionsCellItem
                component={RouterLink}
                icon={<EditIcon />}
                label="Editar"
                to={`update/${id}`}
              />
            </Tooltip>,
            <Tooltip title="Desfazer">
              <> {/* This is to fix a BUG in MUI Tooltip when the button is disabled */}
                <GridActionsCellItem
                  icon={<RestoreIcon />}
                  label="Desfazer"
                  disabled={
                    unsavedChangesRef.current.unsavedRows[id] === undefined
                  }
                  onClick={() => {
                    apiRef.current.updateRows([
                      unsavedChangesRef.current.rowsBeforeChange[id],
                    ]);
                    delete unsavedChangesRef.current.rowsBeforeChange[id];
                    delete unsavedChangesRef.current.unsavedRows[id];
                    setHasUnsavedRows(
                      Object.keys(unsavedChangesRef.current.unsavedRows)
                        .length > 0
                    );
                  }}
                />
              </>
            </Tooltip>,
            <GridActionsCellItem
              showInMenu
              component={Link}
              icon={<OpenInNewIcon />}
              label="Abrir página"
              href={row.absolute_url}
              target="_blank"
            />,
            <GridActionsCellItem
              showInMenu
              icon={<DeleteIcon />}
              label="Excluir"
              onClick={() => {
                unsavedChangesRef.current.unsavedRows[id] = {
                  ...row,
                  _action: "delete",
                };
                if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
                  unsavedChangesRef.current.rowsBeforeChange[id] = row;
                }
                setHasUnsavedRows(true);
                apiRef.current.updateRows([row]); // to trigger row render
              }}
            />,
          ];
        },
      },
    ];
  };
};
