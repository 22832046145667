import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SaveIcon from '@mui/icons-material/Save';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import Tooltip from "@mui/material/Tooltip";
import Loader from 'components/Loader';
import ModifiedBy from 'components/ModifiedBy';
import { useFormik } from "formik";
import React, { useCallback,  useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateCategoryMutation, useDeleteCategoryMutation, useUpdateCategoryMutation } from "state/api";
import * as Yup from "yup";
import SeoForm from 'components/SeoForm';
import SocialForm from 'components/SocialForm';
import BaseConfigurationForm from './BaseConfigurationsForm';
import formDataFromJson from 'utils/formDataFromJson';


const categorySchema = Yup.object({
  id: Yup.string(),
  title: Yup.string(),
  subtitle: Yup.string(),
  description: Yup.string(),
  after_coupons: Yup.string(),
  before_coupons: Yup.string(),
  meta_description: Yup.string().max(160, "Este campo tem um tamanho máximo de ${max} caracteres"),
  og_title: Yup.string(),
  og_description: Yup.string().max(160, "Este campo tem um tamanho máximo de ${max} caracteres"),
  image: Yup.mixed().nullable(),
  og_image: Yup.mixed().nullable(),
  indexable: Yup.boolean().required(),
  followable: Yup.boolean().required(),

  name: Yup.string().required("O nome da categoria é obrigatório."),
  slug: Yup.string().required("O slug é obrigatório."),
  is_popular: Yup.boolean(),
  weight: Yup.number()
    .positive("Apenas números positivos")
    .integer("Apenas números inteiros")
    .required("É obrigatório."),
  max_coupon_on_page: Yup.number()
    .min(0, "Apenas números positivos")
    .integer("Apenas números inteiros")
    .required("É obrigatório."),
  num_max_coupons_store: Yup.number()
    .positive("Apenas números positivos")
    .integer("Apenas números inteiros")
    .required("É obrigatório."),
  related_categories: Yup.array().of(Yup.string()),
  related_specials: Yup.array().of(Yup.string()).ensure(),
  alternative_slugs: Yup.array().of(Yup.object({id: Yup.string().optional(), name: Yup.string()})),
});

const defaultValue = {
  "id": "",
  "title": "",
  "subtitle": "",
  "description": "",
  "after_coupons": "",
  "before_coupons": "",
  "meta_description": "",
  "og_title": "",
  "og_description": "",
  "image": null,
  "og_image": null,
  "indexable": true,
  "followable": true,
  "name": "",
  "slug": "",
  "is_popular": false,
  "weight": 1,
  "max_coupon_on_page": 0,
  "num_max_coupons_store": 3,
  "last_modified_by": null,
  "last_modified": null,
  "related_categories": [],
  "related_specials": [],
  "alternative_slugs": [],
}

const CategoryForm = ({title, category = false}) => {
  const { id } = useParams();
  const form = useRef();
  const [activeTab, setActiveTab] = useState("1");
  const [formSnack, setFormSnack] = useState();
  const [confirmDeletionIsOpen, setConfirmDeletionIsOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [createCategory] = useCreateCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  const formik = useFormik({
    initialValues: category?.data || defaultValue,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = formDataFromJson(categorySchema.cast(values));
        let resp;
        if (id) {
          resp = await updateCategory({ id, formData });
        } else {
          resp = await createCategory(formData);
          navigate("/categories");
        }
        if(!resp.error){
          setFormSnack({ msg: "Formulário salvo com sucesso", severity: "success" });
        } else {
          console.error(resp.error);
          setFormSnack({ msg:resp.error.data[0] , severity: "error" });
        }
      } catch (e) {
        setFormSnack({ msg: "Erro ao salvar formulário", severity: "error" });
        console.error(e);
      }
    },
    validationSchema: categorySchema,
    validateOnBlur: false,
    enableReinitialize: true
  });

  const handleTabChange = useCallback((event, newTab) => {
    setActiveTab(newTab);
  }, []);

  const handleFormSnackClose = useCallback(() => setFormSnack(undefined), [])

  const handleDelete = useCallback(() => {
    try {
      setConfirmDeletionIsOpen(false);
      setIsLoading(true)
      deleteCategory(id);
      setIsLoading(false);
      setFormSnack({ msg: "Categoria removida com sucesso", severity: "success" })
      setTimeout(() => {
        setFormSnack(false);
        navigate("/categories");
      }, 2000)
    } catch (error) {
      setFormSnack({ msg: "Erro ao excluir categoria", severity: "error" })
      console.error(error);
    }
  }, [id]);

  const FormActions = useCallback(() => {
    return (
      <>
        <Tooltip title={formik.isValid ? "Salvar" : "Fomulário com erro"}>
          <span>
            <IconButton disabled={!formik.isValid} form="catForm" type="submit" size="medium" color="secondary">
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Clonar">
          <span>
            <IconButton disabled={!category} size="medium" color="secondary" >
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Visitar">
          <span>
            <IconButton href={category?.data?.absolute_url} disabled={!category} target="_blank" size="medium" color="secondary">
              <PreviewIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Excluir">
          <span>
            <IconButton onClick={() => setConfirmDeletionIsOpen(true)} disabled={!category} size="medium" color="secondary">
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    )
  }, [category, formik.isValid])

  if ((category?.data === undefined || isLoading) && category !== false) {
    return <Loader />;
  }

  return (
    <>
      <Card elevation={2} sx={{ width: "90%" }}>
        <CardHeader
          title={title}
          subheader={<ModifiedBy {...category?.data} />}
          action={<FormActions />}
          subheaderTypographyProps={{ variant: "caption" }}
        />
        <Divider />
        <CardContent>
          <form ref={form} id="catForm" onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <TabContext value={activeTab}>
              <TabList onChange={handleTabChange} centered>
                <Tab label="Básicas" value="1" />
                <Tab label="SEO" value="2" />
                <Tab label="Social" value="3" />
              </TabList>
              <Divider variant="middle" />
              <TabPanel value="1">
                <BaseConfigurationForm category={category?.data} formik={formik} />
              </TabPanel>
              <TabPanel value="2">
                <SeoForm formik={formik} />
              </TabPanel>
              <TabPanel value="3">
                <SocialForm object={category?.data} formik={formik} />
              </TabPanel>
            </TabContext>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        open={Boolean(formSnack)}
        autoHideDuration={5000}
        onClose={handleFormSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={formSnack?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {formSnack?.msg}
        </Alert>
      </Snackbar>
      <Dialog
        open={Boolean(confirmDeletionIsOpen)}
      >
        <DialogTitle>
          <ReportProblemOutlinedIcon fontSize="large" color="warning" sx={{ verticalAlign: "bottom" }} />
          {"Tem certeza que deseja excluir essa categoria?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained" color="warning">Sim</Button>
          <Button onClick={() => setConfirmDeletionIsOpen(false)}>Não</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoryForm;
