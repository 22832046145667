import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTheme, Button } from '@mui/material';
import { useGetCrawledCuponsQuery } from 'state/api';
import Header from 'components/Header';
import getColumns from './columns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { differenceInHours } from 'utils/dateTime';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { alpha } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .crawled-coupon--new': {
    backgroundColor: alpha(theme.palette.success.light, 0.2),
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.light, 0.5),
    },
  },
  '& .crawled-coupon--old': {
    backgroundColor: alpha(theme.palette.warning.light, 0.2),
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.light, 0.5),
    },
  }
}));


const CrawledCoupons = () => {
  const theme = useTheme();
  const [page, setPage] = useState(2);
  const [pageSize, setPageSize] = useState(20);
  const { data = { results: [] }, isLoading } = useGetCrawledCuponsQuery();

  const [spiderOptions, setSpiderOptions] = useState([]);
  const [selectedSpider, setSelectedSpider] = useState('');

  const [isFilterOn, setIsFilterOn] = useState(true);
  const [filters, setFilters] = useState({ store_name: "", is_on_tr: "", is_new: "" });

  const handleFiltersChange = useCallback((event) => {
    const { name, value } = event.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  }, []);

  const filteredData = useMemo(() => {
    if (!Array.isArray(data.results)) return [];
    return data.results.filter(item =>
      (!selectedSpider || item.spider === selectedSpider) &&
      Object.entries(filters).every(([key, value]) => {
        if (value === "") return true;
        if (typeof item[key] === "boolean") {
          return item[key].toString().toLowerCase().startsWith(value.toLowerCase());
        }
        return item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase());
      })
    );
  }, [data.results, filters, selectedSpider]);

  useEffect(() => {
    if (Array.isArray(data.results)) {
      const uniqueSpiders = Array.from(new Set(data.results.map(item => item.spider))).sort();
      setSpiderOptions(uniqueSpiders);
    }
  }, [data.results]);

  const handleSpiderChange = (event) => {
    setSelectedSpider(event.target.value);
  };

  const handleExport = () => {
    const csvHeader = columns.map((col) => `"${col.headerName}"`).join(',');
    const csvRows = filteredData.map((row) =>
      columns.map((col) => {
        const cellValue = row[col.field];
        return `"${cellValue !== null && cellValue !== undefined ? cellValue.toString().replace(/"/g, '""') : ''}"`;
      }).join(',')
    );
    const csvContent = [csvHeader, ...csvRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = getColumns(isFilterOn, filters, handleFiltersChange, spiderOptions, selectedSpider, handleSpiderChange);

  const getRowClassName = React.useCallback(({ row }) => {
    const now = new Date();
    const firstSeen = new Date(row.first_time_seen);
    const lastSeen = new Date(row.last_time_seen);

    if (differenceInHours(now, lastSeen) > 72) {
      return "crawled-coupon--old";
    } else if (differenceInHours(now, firstSeen) < 24) {
      return "crawled-coupon--new";
    }
  }, []);

  return (
    <Grid >
      <Header title="CUPONS DO CRAWLER" subtitle="Tabela dos Cupons" />
      <Button
        variant="contained"
        color="primary"
        startIcon={<FileDownloadIcon />}
        onClick={handleExport}
        sx={{ marginBottom: '16px' }}
      >
        Exportar CSV
      </Button>
      <Box
        height="80vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: 'none',
            fontWeight: 'bold',
            fontSize: '1.1rem',
          },
          '& .MuiDataGrid-columnHeader': {
            height: '100px !important',
            minHeight: '100px !important',
            maxHeight: '100px !important',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: '#FFFFFF',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: 'none',
          },
        }}
      >
        <StyledDataGrid
          loading={isLoading || !filteredData}
          getRowId={(row) => row.id}
          rows={filteredData || []}
          columns={columns}
          getRowClassName={getRowClassName}
          experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          pagination
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setPage(0);
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
          paginationMode="client"
          headerHeight={100}
        />
      </Box>
    </Grid>
  );
};
export default CrawledCoupons;
