import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from "@mui/icons-material/Restore";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter";
import * as React from "react";
import { Link as RouterLink } from 'react-router-dom';

const DataGridEditableRowToolbar = React.forwardRef(function GridToolbar(props, ref) {
  const {
    isSaving,
    hasUnsavedRows,
    discardAllChanges,
    saveAllChanges,
    createLink,
    ...other
  } = props;

  return (
    <GridToolbarContainer ref={ref} {...other} >
      <GridToolbarQuickFilter />
      <Box sx={{ flex: 1 }} />
      <Tooltip title="Adicionar novo item">
        <> {/** Workaround to fix a BUG in MUI Tooltip when the button is disabled */}
          <IconButton component={RouterLink}
            aria-label="Adicionar novo item"
            to={createLink}
          >
            <AddIcon />
          </IconButton>
        </>
      </Tooltip>
      <Tooltip title="Descartar todas alterações">
        <> {/** Workaround to fix a BUG in MUI Tooltip when the button is disabled */}
          <IconButton
            aria-label="Descartar todas alterações"
            disabled={!hasUnsavedRows || isSaving}
            onClick={discardAllChanges}
          >
            <RestoreIcon />
          </IconButton>
        </>
      </Tooltip>
      <Tooltip title="Salvar todas alterações">
        <> {/** Workaround to fix a BUG in MUI Tooltip when the button is disabled */}
          <IconButton
            color="warning"
            disabled={!hasUnsavedRows}
            onClick={saveAllChanges}
            aria-label="Salvar todas alterações"
          >
            <SaveIcon />
          </IconButton>
        </>
      </Tooltip>
    </GridToolbarContainer>
  );
});

export { DataGridEditableRowToolbar };
