import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from "@mui/material/Tooltip";
import Loader from 'components/Loader';
import ModifiedBy from 'components/ModifiedBy';
import { useFormik } from "formik";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateNetworkMutation, useDeleteNetworkMutation, useGetTrackingMethodsQuery, useUpdateNetworkMutation, useGetTrackedUrlPreviewMutation } from "state/api";
import * as Yup from "yup";
import FormikTextField from 'components/FormikTextField';
import FormikSelect from 'components/FormikSelect';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import FormikJsonField from 'components/FormikJsonField';
import TextField from '@mui/material/TextField';


const networkSchema = Yup.object({
  name: Yup.string().required("O nome da rede é obrigatório."),
  tracking_method: Yup.string(),
  template: Yup.string().optional(),
  params: Yup.object({ key: Yup.string(), value: Yup.string() }).optional(),
});

const defaultValue = {
  "name": "",
  "tracking_method": "",
  "template": "",
  "params": {},
}

const NetworkForm = ({ title, network = false }) => {
  const { id } = useParams();
  const form = useRef();
  const [formSnack, setFormSnack] = useState();
  const [confirmDeletionIsOpen, setConfirmDeletionIsOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [createNetwork] = useCreateNetworkMutation();
  const [updateNetwork] = useUpdateNetworkMutation();
  const [deleteNetwork] = useDeleteNetworkMutation();
  const trackingMethods = useGetTrackingMethodsQuery();
  const [getTrackedUrlPreview] = useGetTrackedUrlPreviewMutation();
  const [preview, setPreview] = useState({url: "http://preview-url.com/?param1=Wood&param2=stock"})

  const formik = useFormik({
    initialValues: network?.data || defaultValue,
    onSubmit: async (values, { resetForm }) => {
      try {
        const body = networkSchema.cast(values);
        let resp;
        if (id) {
          resp = await updateNetwork({ id, body });
        } else {
          resp = await createNetwork(body);
          navigate("/networks");
        }
        if (!resp.error) {
          setFormSnack({ msg: "Formulário salvo com sucesso", severity: "success" });
        } else {
          console.error(resp.error);
          setFormSnack({ msg: resp.error.data[0], severity: "error" });
        }
      } catch (e) {
        setFormSnack({ msg: "Erro ao salvar formulário", severity: "error" });
        console.error(e);
      }
    },
    validationSchema: networkSchema,
    validateOnBlur: false,
    enableReinitialize: true
  });

  const handleFormSnackClose = useCallback(() => setFormSnack(undefined), [])

  const handleDelete = useCallback(() => {
    try {
      setConfirmDeletionIsOpen(false);
      setIsLoading(true)
      deleteNetwork(id);
      setIsLoading(false);
      setFormSnack({ msg: "Rede removida com sucesso", severity: "success" })
      setTimeout(() => {
        setFormSnack(false);
        navigate("/networks");
      }, 2000)
    } catch (error) {
      setFormSnack({ msg: "Erro ao excluir a rede", severity: "error" })
      console.error(error);
    }
  }, [id]);

  const FormActions = useCallback(() => {
    return (
      <>
        <Tooltip title={formik.isValid ? "Salvar" : "Fomulário com erro"}>
          <span>
            <IconButton disabled={!formik.isValid} form="networkForm" type="submit" size="medium" color="secondary">
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Clonar">
          <span>
            <IconButton disabled={!network} size="medium" color="secondary" >
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Excluir">
          <span>
            <IconButton onClick={() => setConfirmDeletionIsOpen(true)} disabled={!network} size="medium" color="secondary">
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    )
  }, [network, formik.isValid])

  useEffect(() => {
    async function get () {
      const { data } = await getTrackedUrlPreview({
        url: preview.url,
        ...formik.values
      })
      setPreview(old => ({...old, result: data?.preview}));
    }
    get()
  }, [formik.values, preview.url]);

  function handlePreviewChange(e) {
    setPreview({url: e.target.value, result: ""});
  }

  if ((network !== false && network?.data === undefined) || isLoading | trackingMethods.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Card elevation={2} sx={{ width: "90%" }}>
        <CardHeader
          title={title}
          subheader={<ModifiedBy {...network?.data} />}
          action={<FormActions />}
          subheaderTypographyProps={{ variant: "caption" }}
        />
        <Divider />
        <CardContent>
          <form ref={form} id="networkForm" onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <Grid container spacing={2} rowSpacing={1}>
              <Grid xs={6} textAlign="center">
                <FormikTextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Nome"
                  formik={formik}
                />
              </Grid>
              <Grid xs={6} textAlign="center">
                <FormikSelect
                  fullWidth
                  name="tracking_method"
                  label="Método de trackeamento"
                  formik={formik}
                  options={trackingMethods.data}
                />
              </Grid>
              <Grid xs={12} textAlign="center">
                <FormikTextField
                  fullWidth
                  id="template"
                  name="template"
                  label="Template"
                  formik={formik}
                  disabled={formik.values["tracking_method"] != "template_builder"}
                />
              </Grid>
              <Grid xs={12} textAlign="center">
                <FormikJsonField
                  fullWidth
                  id="params"
                  name="params"
                  label="Parâmetros"
                  formik={formik}
                  clearButton
                />
              </Grid>
            </Grid>
          </form>
          <Divider variant="middle" sx={{mt:10}}>
            Pré-visualização
          </Divider>
          <Grid xs={12} textAlign="center" sx={{mt:3}}>
            <TextField
              fullWidth
              name="url_preview"
              label="URL para teste"
              value={preview.url}
              onChange={handlePreviewChange}
            />
          </Grid>
          <Grid xs={12} textAlign="center" sx={{mt:3}}>
            <TextField
              fullWidth
              name="preview_result"
              label="Resultado"
              value={preview?.result || ""}
              disabled
            />
          </Grid>
        </CardContent>
      </Card>
      <Snackbar
        open={Boolean(formSnack)}
        autoHideDuration={5000}
        onClose={handleFormSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={formSnack?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {formSnack?.msg}
        </Alert>
      </Snackbar>
      <Dialog
        open={Boolean(confirmDeletionIsOpen)}
      >
        <DialogTitle>
          <ReportProblemOutlinedIcon fontSize="large" color="warning" sx={{ verticalAlign: "bottom" }} />
          {"Tem certeza que deseja excluir esta Rede?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained" color="warning">Sim</Button>
          <Button onClick={() => setConfirmDeletionIsOpen(false)}>Não</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NetworkForm;
