import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import React, { useCallback } from "react";


export default function FormikAutocomplete({ name, label, placeholder, formik, ...props }) {

  const getKey = useCallback(option => option?.id || option?.name || option, []);
  const getLabel = useCallback(option => option?.name || option, []);

  function renderOption(props, option, { selected }) {
    const { key, ...optionProps } = props;

    return (
      <li key={key} {...optionProps}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option?.name || option}
      </li>
    );
  };

  const renderOptionMemoized = useCallback(renderOption, []);

  const setRelatedValues = useCallback((event, values, reason) => {
    formik.setFieldValue(name, values.map(option => option?.id || option?.name || option));
  }, [name]);

  return (
    <Autocomplete
      multiple
      limitTags={2}
      disableCloseOnSelect
      onChange={setRelatedValues}
      getOptionLabel={getLabel}
      getOptionKey={getKey}
      renderOption={renderOptionMemoized}
      renderInput={(params) => (
        <TextField {...params} type="filled" label={label} placeholder={placeholder} />
      )}
      name={name}
      label={label}
      {...props}
    />
  )
};
