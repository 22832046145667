export const networks_endpoints = {
  endpoints: (build) => ({
    getNetworks: build.query({
      query: () => ({
        url: "networks/",
        method: "GET",
      }),
      providesTags: ["Networks"],
    }),
    getNetwork: build.query({
      query: (id) => ({
        url: `networks/${id}`,
        method: "GET",
      }),
      providesTags: ["Networks"],
    }),
    createNetwork: build.mutation({
      query: (body) => ({
        url: "networks/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Networks"],
    }),
    updateNetwork: build.mutation({
      query: ({ id, body }) => ({
        url: `networks/${id}/`,
        method: "PUT",
        body
      }),
      invalidatesTags: [{ type: "Networks", id: "LIST" }],
    }),
    deleteNetwork: build.mutation({
      query: (id) => ({
        url: `networks/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Networks", id: "LIST" }],
    }),
    getTrackingMethods: build.query({
      providesTags: ["TrackingMethods"],
      query: (params) => ({
        url: "networks/tracking-methods/",
        method: "GET",
      }),
    }),
    getTrackedUrlPreview: build.mutation({
      providesTags: ["TrackedURL"],
      query: (body) => ({
        url: "networks/preview/",
        method: "POST",
        body
      }),
    }),
  }),
};
