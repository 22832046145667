import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export default function FormikSelect(props) {
  const { name, label, formik, options } = props;

  return (
    <FormControl fullWidth margin="dense">
      <InputLabel id={`label-${name}`}>{label}</InputLabel>
      <Select
        labelId={`label-${name}`}
        name={name}
        value={formik.values[name]}
        label={label}
        onChange={formik.handleChange}
      >
        {options.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
      </Select>
    </FormControl>
  );
}
