import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestoreIcon from "@mui/icons-material/Restore";
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { GridActionsCellItem } from "@mui/x-data-grid";
import renderCellExpand from "components/GridCellExpand";
import { Link as RouterLink } from 'react-router-dom';
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import StarIcon from '@mui/icons-material/Star';

export const getColumns = (apiRef, unsavedChangesRef, setHasUnsavedRows) => ([
  {
    field: "image",
    headerName: "Imagem",
    cellClassName: 'logo--cell',
    renderCell: ({value, row}) => {
      return <Avatar src={value} alt={row.name} variant="rounded"><AppShortcutIcon /></Avatar>
    },
    filterable: false,

  },
  {
    field: "name",
    headerName: "Nome",
    renderCell: renderCellExpand,
    flex: 1,
    editable: true,
  },
  {
    field: "slug",
    headerName: "Slug Principal",
    flex: 1,
    editable: true,
  },
  {
    field: "description",
    headerName: "Descrição",
    renderCell: renderCellExpand,
    flex: 1,
    editable: true,
    sortable: false,
  },
  {
    field: "is_popular",
    headerName: <StarIcon/>,
    description: "É Popular?",
    type: "boolean",
    editable: true,
    sortable: false,
    flex: 0.3,
  },
  {
    field: "weight",
    headerName: <FitnessCenterIcon/>,
    description: "Peso",
    type: "number",
    editable: true,
    sortable: false,
    flex: 0.3,
  },
  {
    field: "actions",
    headerName: "Ações",
    type: "actions",
    flex: 0.5,
    getActions: ({ id, row }) => {
      return [
        <Tooltip title="Editar">
          <GridActionsCellItem component={RouterLink}
            icon={<EditIcon />}
            to={`/specials/update/${id}`}
            label="Editar"
          />
        </Tooltip>,
        <Tooltip title="Desfazer">
          <> {/* This is to fix a BUG in MUI Tooltip when the button is disabled */}
            <GridActionsCellItem
              icon={<RestoreIcon />}
              label="Desfazer"
              disabled={unsavedChangesRef.current.unsavedRows[id] === undefined}
              onClick={() => {
                apiRef.current.updateRows([
                  unsavedChangesRef.current.rowsBeforeChange[id],
                ]);
                delete unsavedChangesRef.current.rowsBeforeChange[id];
                delete unsavedChangesRef.current.unsavedRows[id];
                setHasUnsavedRows(
                  Object.keys(unsavedChangesRef.current.unsavedRows).length > 0
                );
              }}
            />
          </>
        </Tooltip>,
        <GridActionsCellItem
          showInMenu
          component={Link}
          icon={<OpenInNewIcon />}
          label="Abrir página"
          href={row.absolute_url}
          target="_blank"
        />,
        <GridActionsCellItem
          showInMenu
          icon={<DeleteIcon />}
          label="Excluir"
          onClick={() => {
            unsavedChangesRef.current.unsavedRows[id] = {
              ...row,
              _action: "delete",
            };
            if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
              unsavedChangesRef.current.rowsBeforeChange[id] = row;
            }
            setHasUnsavedRows(true);
            apiRef.current.updateRows([row]); // to trigger row render
          }}
        />,
      ];
    },
  },
]);
