export const stores_endpoints = {
  endpoints: (build) => ({
    getStores: build.query({
      providesTags: ["Stores"],
      query: (params) => ({
        url: "stores/",
        method: "GET",
        params: {
          page: params?.page,
          page_size: params?.pageSize,
          ordering: params?.sort,
          search: params?.search,
          fields: params?.fields,
        },
      }),
    }),
    getStore: build.query({
      query: (id) => ({
        url: `stores/${id}`,
        method: "GET",
      }),
      providesTags: ["Stores"],
    }),
    createStore: build.mutation({
      query: (body) => ({
        url: "stores/",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["Stores"],
    }),
    updateStore: build.mutation({
      query: ({id, body, formData}) => {
        return {
        url: `stores/${id}/`,
        method: "PATCH",
        body: body || formData,
        formData: Boolean(formData),
      }},
      invalidatesTags: [{ type: "Stores", id: "LIST" }],
    }),
    deleteStore: build.mutation({
      query: (id) => ({
        url: `stores/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Stores", id: "LIST" }],
    }),
  }),
};
