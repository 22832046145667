import React, { useState } from "react";
import { Box } from "@mui/material";
import { useGetCouponsQuery, useUpdateCouponsMutation, useDeleteCouponsMutation } from "state/api";
import Header from "components/Header";
import CustomAppBar from "./AppBar";
import getColumns from "./columns";
import DataGridConfirmationDialog from "components/DataGridConfimationDialog";
import DataGridCustomTableClientSide from "components/DataGridCustomTableClientSide";
import { handleDelete, handleEditCommit } from "utils/handlers";



export const Coupons = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const { data, isLoading, refetch } = useGetCouponsQuery({});

  const [updateCouponsMutation] = useUpdateCouponsMutation();
  const [deleteCouponsMutation] = useDeleteCouponsMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const handleOpenDialog = (id) => {
    setIdToDelete(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [errorDialog, setErrorDialog] = useState({
    open: false,
    message: ''
  });

  const showErrorDialog = (message) => {
    setErrorDialog({ open: true, message });
  };


  const columns = getColumns(
    handleOpenDialog, newRow =>
      handleEditCommit(newRow, updateCouponsMutation, showErrorDialog));

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CUPONS" subtitle="" />
      <CustomAppBar />
      <DataGridCustomTableClientSide
        data={data}
        isLoading={isLoading}
        columns={columns}
        handleEditCommit={(newRow) => handleEditCommit(newRow, updateCouponsMutation, showErrorDialog)}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      <DataGridConfirmationDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleConfirm={() => handleDelete(idToDelete, deleteCouponsMutation, refetch, handleCloseDialog)}
        title="Confirmação de exclusão"
        description="Tem certeza de que deseja excluir este item?"
      />
      <DataGridConfirmationDialog
        open={errorDialog.open}
        handleClose={() => setErrorDialog({ ...errorDialog, open: false })}
        title="Erro ao Atualizar"
        description={errorDialog.message}
        handleConfirm={() => setErrorDialog({ ...errorDialog, open: false })}
      />
    </Box>
  );
};

export default Coupons;
