import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_base_url } from "config";
import { categories_endpoints } from "./categories";
import { specials_endpoints } from "./specials";
import { slugs_endpoints } from "./slugs";
import { paramsSerializer } from "utils/paramSerializer";
import { networks_endpoints } from "./networks";
import { stores_endpoints } from "./stores";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${api_base_url}/site/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
    paramsSerializer: paramsSerializer,
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "CrawledCupons",
    "Stores",
    "Categories",
    "Coupons",
    "Specials",
    "Networks",
  ],
  endpoints: (build) => ({
    getCrawledCupons: build.query({
      query: () => ({
        url: "crawled-coupons/",
        method: "GET",
      }),
      providesTags: ["CrawledCupons"],
    }),
    getCoupons: build.query({
      query: () => ({
        url: "coupons",
        method: "GET",
      }),
      providesTags: ["Coupons"],
    }),
    createCoupons: build.mutation({
      query: (body) => ({
        url: "coupons/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Coupons"],
    }),
    updateCoupons: build.mutation({
      query: ({ id, ...body }) => ({
        url: `coupons/${id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: "Coupons", id: "LIST" }],
    }),
    deleteCoupons: build.mutation({
      query: (id) => ({
        url: `coupons/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Coupons", id: "LIST" }],
    }),
  }),
}
).injectEndpoints(
  categories_endpoints
).injectEndpoints(
  slugs_endpoints
)
.injectEndpoints(
  specials_endpoints
)
.injectEndpoints(
  networks_endpoints
)
.injectEndpoints(
  stores_endpoints
);

export const {
  useCreateCategoryMutation,
  useCreateSpecialMutation,
  useCreateCouponsMutation,
  useCreateNetworkMutation,
  useCreateSlugsMutation,
  useCreateStoreMutation,
  useDeleteCategoryMutation,
  useDeleteCouponsMutation,
  useDeleteNetworkMutation,
  useDeleteSpecialMutation,
  useDeleteSlugsMutation,
  useDeleteStoreMutation,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useGetSlugsQuery,
  useGetCouponsQuery,
  useGetCrawledCuponsQuery,
  useGetNetworkQuery,
  useGetNetworksQuery,
  useGetSpecialsQuery,
  useGetSpecialQuery,
  useGetStoresQuery,
  useUpdateCategoryMutation,
  useUpdateCouponsMutation,
  useUpdateNetworkMutation,
  useUpdateSlugMutation,
  useUpdateStoreMutation,
  useUpdateSpecialMutation,
  useGetTrackingMethodsQuery,
  useGetTrackedUrlPreviewMutation,
} = api;
