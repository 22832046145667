import { api_base_url } from 'config';
import { Box } from '@mui/material';
import { useFormik } from "formik";
import { useGetCategoriesQuery, useCreateStoreMutation } from 'state/api';

import * as Yup from 'yup';

import GeralFormComponent from './GeralFormComponent';
import Header from 'components/Header';
import React, { useState, useEffect } from 'react';
import SEOFormComponent from './SEOFormComponent';
import slugify from 'react-slugify';
import SocialMediaFormComponent from './SocialMediaFormComponent';
import TabsComponent from './TabsComponent';

const AddStore = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    fetch(`${api_base_url}/site/api/stores`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setCategoryData(data.results);
        console.log(data);
      })
      .catch(error => {
        console.log('Erro ao obter categorias:', error);
      });
  }, []);

  const [createStoresMutation] = useCreateStoreMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      source_url: '',
      tracked_url: '',
      category: '',
    },
    onSubmit: async (values, { resetForm }) => {
      console.log('Valores do formulário antes do envio:', values);
      try {
        const { name, source_url, tracked_url, category } = values;
        const slug = slugify(name);
        await createStoresMutation({
          name,
          slug,
          source_url,
          tracked_url,
          category,
        });
        resetForm();
      } catch (error) {
        console.error('Erro ao adicionar a loja:', error);
        console.log('Detalhes do erro da API:', error.response);
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required('O nome da loja é obrigatório.'),
      source_url: Yup.string().required('A URL da fonte é obrigatória.'),
      tracked_url: Yup.string().required('A URL rastreada é obrigatória.'),
    }),
  });

  React.useEffect(() => {
    const slug = slugify(formik.values.name);
    formik.setFieldValue('slug', slug);
  }, [formik.values.name]);


  const handleSave = () => {
    console.log('Submissão do formulário:', formik.values);
    formik.handleSubmit();
  };

  const handleSaveAndContinue = () => {
    formik.submitForm();
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const { data: categories, isLoading: isLoadingCategories, isError: isErrorCategories } = useGetCategoriesQuery();

  if (isLoadingCategories) {
    return <div>Carregando...</div>;
  }

  if (isErrorCategories) {
    return <div>Erro ao carregar categorias.</div>;
  }

  const categoryOptions = categories.map(cat => ({ value: cat.id, label: cat.name }));


  return (
    <Box m="20px">
      <Header title="ADICIONAR LOJAS" subtitle="Adiciona nova Loja no Dashboard" />
      <TabsComponent
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      {selectedTab === 0 ? (
        <GeralFormComponent
          formik={formik}
          categoryOptions={categoryOptions}
        />
      ) : selectedTab === 1 ? (
        <SEOFormComponent
          formik={formik}
        />
      ) : selectedTab === 2 ? (
        <SocialMediaFormComponent
          formik={formik}
        />
      ) : null}
    </Box>
  );
};

export default AddStore;
