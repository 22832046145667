export const fieldToHeaderMap = {
    title: "Título",
    description: "Descrição",
    source_url: "URL Fonte"
};

export function formatErrorMessages(errorData) {
    const messages = Object.entries(errorData).map(([field, errors]) => {
        const headerName = fieldToHeaderMap[field] || field;
        const formattedErrors = errors.join(', ');
        return `${headerName}: ${formattedErrors}`;
    });
    return messages.join('\n');
}
