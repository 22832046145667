export const paramsSerializer = (params) => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];

    if (Boolean(value) === false) continue;

    if (Array.isArray(value)) {
      // Serialize arrays with the '[]' notation
      value.forEach((item) => {
        searchParams.append(`${key}`, item);
      });
    } else {
      // Serialize other values
      searchParams.append(key, value);
    }
  }

  return searchParams.toString();
};
