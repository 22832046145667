import React from 'react';
import { FormControl, InputLabel, OutlinedInput, Chip } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';

const CustomMultiSelect = ({ label, id, value, onChange, options }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Autocomplete
        multiple
        id={id}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          onChange({ target: { name: id, value: newValue } });
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <OutlinedInput
            {...params}
            label={label}
            notched
            labelWidth={70}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
          ))
        }
      />
    </FormControl>
  );
};

export default CustomMultiSelect;
