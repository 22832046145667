import FormikAutocomplete from 'components/FormikAutocomplete';
import React, { useMemo } from "react";


function SelectedRelated({name, formik, excluded = [], getter, ...props}) {
  let {data} = getter({fields: ["id", "name"]})

  const options = data ? data.filter(obj => excluded.includes(obj.id) !== true) : [];
  const selectedValues = data ? data.filter(obj => formik.values[name].includes(obj.id)) : [];

  return (
    <FormikAutocomplete
      name={name}
      formik={formik}
      options={options}
      value={selectedValues}
      {...props}
    />
  );
};

export default SelectedRelated;
