import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Header from 'components/Header';
import { useCreateCouponsMutation } from 'state/api';
import TabsComponent from './TabsComponent';
import GeralFormComponent from './GeralFormComponent';
import CodeFormComponent from './CodeFormComponent';


const AddCoupons = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [createCouponsMutation] = useCreateCouponsMutation();

  const formik = useFormik({
    initialValues: {
      title:'',
      description:'',
      tracked_url:'',
      source_url:'',
      store:'',
      category:'',

    },
    onSubmit: async (values, { resetForm }) => {
      console.log('Valores do formulário antes do envio:', values);
      try {
        const {
          title,
          description,
          tracked_url,
          source_url,
          store,
          category,
        } = values;

        await createCouponsMutation({
          title,
          description,
          tracked_url,
          source_url,
          store,
          category,
        });
        resetForm();
      } catch (error) {
        console.error('Erro ao adicionar o cupom:', error);
        console.log('Detalhes do erro da API:', error.response);
      }
    },
    validationSchema: Yup.object({
      title: Yup.string().required('O título do cupom é obrigatório.'),
      description: Yup.string().required('A descrição do cupom é obrigatória.'),
    }),
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSave = () => {
    console.log('Submissão da cupom:', formik.values);
    formik.handleSubmit();
  };

  return (
    <Box m="20px">
      <Header title="NOVO CUPOM" subtitle="Adiciona novo cupom no Dashboard" />
        <TabsComponent
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        handleSave={handleSave}/>
        {selectedTab === 0 ? (
            <GeralFormComponent
                formik={formik}
            />
            )
            : selectedTab === 1 ? (
            <CodeFormComponent
                formik={formik}
            />
            ) : null}
    </Box>
  );
};

export default AddCoupons;
