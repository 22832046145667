import React from 'react';
import {
  TextField,
  Stack,
  useTheme,
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';


const SEOFormComponent = ({
  formik,
}) => {
  const theme = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    onDrop: (acceptedFiles) => {
      formik.setFieldValue('og_image', acceptedFiles[0]);
    },
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          label="Título da Página"
          variant="outlined"
          fullWidth
          id="seo_title"
          name="seo_title"
          value={formik.values.seo_title}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: false,
          }}
        />
        <TextField
          label="Subtítulo"
          variant="outlined"
          fullWidth
          id="subtitle"
          name="subtitle"
          value={formik.values.subtitle}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: false,
          }}
        />
        <TextField
          label="Meta Description"
          variant="outlined"
          fullWidth
          id="meta_description"
          name="meta_description"
          value={formik.values.meta_description}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: false,
          }}
        />
         <FormControlLabel
          control={
            <Checkbox
              id="indexable"
              name="indexable"
              checked={formik.values.indexable}
              onChange={formik.handleChange}
              color="primary"
              value={formik.values.indexable}
            />
          }
          label="É indexável?"
          sx={{ gridColumn: "span 3", color: theme.palette.text.primary[100] }}
        />
        <FormControlLabel
          control={
            <Checkbox
              id="follow_links"
              name="follow_links"
              checked={formik.values.follow_links}
              onChange={formik.handleChange}
              color="primary"
              value={formik.values.follow_links}
            />
          }
          label="Segue Links?"
          sx={{ gridColumn: "span 3", color: theme.palette.text.primary[100] }}
        />
        <Paper elevation={3} variant="outlined" sx={{
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[200],
            p: 2
          }}>
          <Typography variant="subtitle1">Imagem OG</Typography>
          <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            <p>Arraste e solte uma imagem ou clique aqui para selecionar uma imagem.</p>
          </div>
          {formik.values.og_image && (
            <p>Imagem selecionada: {formik.values.og_image.name}</p>
          )}
        </Paper>
      </Stack>
    </form>
  );
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default SEOFormComponent;
