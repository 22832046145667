import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import React, { useState } from "react";
import VisuallyHiddenInput from './VisuallyHiddenInput';




export default function FormikAvatar({ name, formik, src, label }) {
  const [img, setImg] = useState(null);

  function loadImg(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function(e) {
      setImg(reader.result)
      formik.setFieldValue(name, file)
    }
    reader.readAsDataURL(file);
  }

  return (
    <>
      <ButtonBase
        role={undefined}
        tabIndex={-1}
        component="label"
        sx={{flexDirection: "column", typography: 'caption' }}
      >
        <Avatar
          variant="rounded"
          sx={{ width: 192 , height: 192, border: "1px solid lightgray" }}
          src={img || src}
          />
        {label}
        <VisuallyHiddenInput name={name} type="file" accept="image/*" onChange={loadImg}/>
      </ButtonBase>
    </>
  )
}
