import React from 'react';
import { TextField } from '@mui/material';


const CustomTextField = ({
    label,
    id,
    name,
    value,
    onChange,
    multiline,
    rows }) => {
      const handleChange = (e) => {
          console.log(e.target.name, e.target.value); // Para diagnóstico
          onChange(e); // Chama formik.handleChange
      };


    return (
        <TextField
          label={label}
          variant="outlined"
          fullWidth
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          multiline={multiline}
          rows={rows || 1}
        />
    );
};

export default CustomTextField;
