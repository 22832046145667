import React from 'react';
import {
  TextField,
  Stack,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';


const SocialMediaFormComponent = ({
  formik
}) => {
  const theme = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    onDrop: (acceptedFiles) => {
      formik.setFieldValue('og_image', acceptedFiles[0]);
    },
    });


  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          label="Título og"
          variant="outlined"
          fullWidth
          id="og_title"
          name="og_title"
          value={formik.values.og_title}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: false,
          }}
        />
        <TextField
          label="Descrição og"
          variant="outlined"
          fullWidth
          id="og_description"
          name="og_description"
          value={formik.values.og_description}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: false,
          }}
        />
        <Paper elevation={3} variant="outlined" sx={{
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[200],
            p: 2
          }}>
          <Typography variant="subtitle1">Imagem OG</Typography>
          <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            <p>Arraste e solte uma imagem ou clique aqui para selecionar uma imagem.</p>
          </div>
          {formik.values.og_image && (
            <p>Imagem selecionada: {formik.values.og_image.name}</p>
          )}
        </Paper>
        {/* Outros campos relacionados */}
      </Stack>
    </form>
  );
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default SocialMediaFormComponent;
