import StoreIcon from '@mui/icons-material/Store';
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip, TextField, Box, Select, Typography, MenuItem } from "@mui/material";
import FilterTextField from 'components/FilterTextField';

const getColumns = (isFilterOn, filters, handleFiltersChange, spiderOptions, selectedSpider, handleSpiderChange) => [
  {
    field: 'store_name',
    headerName: 'Nome da Loja',
    width: 200,
    renderHeader: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div>Nome da Loja</div>
        {isFilterOn && (
          <TextField
            name="store_name"
            value={filters.store_name}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
          />
        )}
      </div>
    ),
  },
  {
    field: "spider",
    headerName: "Spider",
    width: 160,
    renderHeader: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>Spider</div>
        {isFilterOn && (
          <Select
            value={selectedSpider}
            onChange={handleSpiderChange}
            displayEmpty
            sx={{
              mt: 1,
              color: 'inherit',
              bgcolor: 'background.paper',
              borderRadius: 1,
              '& .MuiSvgIcon-root': {
                color: 'inherit',
              },
            }}
          >
            <MenuItem value=""><em>Selecione</em></MenuItem>
            {spiderOptions.map(spider => (
              <MenuItem key={spider} value={spider}>{spider}</MenuItem>
            ))}
          </Select>
        )}
      </div>
    ),
  },
  {
    field: "code",
    headerName: "Código",
    width: 150,
    renderHeader: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div>Código</div>
        {isFilterOn && (
          <TextField
            name="code"
            value={filters.code}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
          />
        )}
      </div>
    ),
  },
  {
    field: "store_slug",
    headerName: "SLUG",
    width: 150,
    renderHeader: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div>SLUG</div>
        {isFilterOn && (
          <TextField
            name="store_slug"
            value={filters.store_slug}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
          />
        )}
      </div>
    ),
  },
  {
    field: "is_on_tr",
    headerName: "TR?",
    width: 150,
    renderHeader: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div>TR</div>
        {isFilterOn && (
          <TextField
            name="is_on_tr"
            value={filters.is_on_tr}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
          />
        )}
      </div>
    ),
  },
  {
    field: "store_url",
    headerName: "URL da Loja",
    minWidth: 150,
    renderHeader: (params) => (
      <strong>{params.colDef.headerName}</strong>
    ),
    renderCell: (params) => {
      return (
        <a href={`${params.value}`} rel="noopener noreferrer" target="_blank">
          <StoreIcon style={{ color: 'grey', fontSize: "32px" }} />
        </a>
      );
    }
  },
  {
    field: "title",
    headerName: "Título",
    flex: 1,
    minWidth: 400,
    renderHeader: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div>Título</div>
        {isFilterOn && (
          <TextField
            name="title"
            value={filters.title}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
          />
        )}
      </div>
    ),
  },
  {
    field: "description",
    headerName: "Descrição",
    minWidth: 450,
    renderHeader: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div>Descrição</div>
        {isFilterOn && (
          <TextField
            name="description"
            value={filters.description}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
          />
        )}
      </div>
    ),
  },
  {
    field: "url",
    headerName: "URL",
    flex: 1,
    minWidth: 100,
    renderHeader: (params) => (
      <strong>{params.colDef.headerName}</strong>
    ),
    renderCell: (params) => (
      <a href={`${params.value}`} rel="noopener noreferrer" target="_blank">
        <LinkIcon style={{ color: 'grey', fontSize: "32px" }} />
      </a>
    )
  },
  {
    field: "last_time_seen",
    headerName: "Visto por último",
    flex: 1,
    minWidth: 200,
    renderHeader: (params) => isFilterOn ? (
      <TextField
        type="date"
        value={filters.last_time_seen || ''}
        onChange={(event) => handleFiltersChange({ target: { name: 'last_time_seen', value: event.target.value } })}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ width: '100%' }}
      />
    ) : "Visto por último"
  },
  {
    field: "first_time_seen",
    headerName: "first_time_seen",
    minWidth: 200,
    renderHeader: (params) => isFilterOn ? (
      <TextField
        type="date"
        value={filters.first_time_seen || ''}
        onChange={(event) => handleFiltersChange({ target: { name: 'first_time_seen', value: event.target.value } })}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ width: '100%' }}
      />
    ) : "Visto primeira vez"
  },

];

export default getColumns;
