import React from 'react';
import StoreIcon from "@mui/icons-material/Store";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';


export const getColumns = (handleDeleteCoupons, handleEditCoupons) => [
    {
        field: "title",
        headerName: "Título",
        flex: 1,
        editable: true,
        onCellEditCommit: (params) => {
            handleEditCoupons(params.id, params.field, params.value);
          },
    },
    {
        field: "description",
        headerName: "Descrição",
        flex: 1,
    },
    {
        field: "source_url",
        headerName: "URL Fonte",
        flex: 1,
        editable: true,
    },
    {
        field: "expiration_date",
        headerName: "Data de Expiração",
        valueFormatter: params =>
            new Date(params?.value).toLocaleString("pt-BR"),
        flex: 1,
    },
    {
        field: "add_date",
        headerName: "Data de Adição",
        valueFormatter: params =>
            new Date(params?.value).toLocaleString("pt-BR"),
        flex: 1,
    },
    {
        field: "start_date",
        headerName: "Data de Início",
        valueFormatter: params =>
            new Date(params?.value).toLocaleString("pt-BR"),
        flex: 1,
    },
    {
        field: "tracked_url",
        headerName: "URL",
        flex: 1,
        renderCell: (params) => {
            return (
                <a href={`${params.value}`} rel="noopener noreferrer" target="_blank">
                    <StoreIcon style={{ color: 'grey', fontSize: "32" }} />
                </a>
            );
        }
    },
    {
        field: "text",
        headerName: "Texto",
        flex: 1,
    },
    {
        field: "store",
        headerName: "Loja",
        flex: 1,
    },
    {
        field: "categories",
        headerName: "Categorias",
        flex: 1,
    },
    {
        field: "actions",
        headerName: "Ações",
        sortable: false,
        width: 150,
        renderCell: (params) => (
            <IconButton
                color="secondary"
                size="small"
                onClick={() => handleDeleteCoupons(params.id)}
            >
              <DeleteIcon />
            </IconButton>
           ),
    }
];

export default getColumns;
