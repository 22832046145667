import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Button } from "@mui/material";
import Alert from '@mui/material/Alert';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import axios from "axios";
import { api_base_url } from "config";
import * as React from "react";
import { useState } from "react";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const submit = async e => {
    e.preventDefault();

    const user = {
      username: username,
      password: password
    };

    const url = api_base_url + "/site/dashboard/token/"

    try {
      const { data } = await axios.post(url, user, {
        headers: {
          "Content-Type": "application/json"
        }
      }, { withCredentials: true });

      localStorage.clear();
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      axios.defaults.headers.common["Authorization"] = `Bearer ${data["access"]}`;
      window.location.href = "/"
    } catch (error) {
      console.error(error);
      setErrorMessage("Erro ao fazer login");
    }
  }

  return (
    <Grid container height="100%" justifyContent="center" alignItems="center" sx={{ backgroundColor: "snoopy.orange.main" }}>
      <Grid xs={8}>
        <Card sx={{ height: "70vh" }}>
          <CardMedia
            component="img"
            image="/snoopy-welcome.gif"
            alt="Snoopy dando boas vindas"
            sx={{
              height: "45vh",
              width: "auto !important",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <CardContent sx={{ textAlign: "center" }}>
            <form onSubmit={submit}>
              <TextField
                variant="outlined"
                label="Nome de usuário"
                name="username"
                type="text"
                value={username}
                required
                onChange={e => setUsername(e.target.value)}
              />
              <br />
              <br />
              <TextField
                variant="outlined"
                name="password"
                type="password"
                label="Senha"
                value={password}
                required
                onChange={e => setPassword(e.target.value)}
              />
              <br />
              <br />
              <Button type="submit" variant="contained" endIcon={<FlightTakeoffIcon />} sx={{ backgroundColor: "snoopy.yellow.main" }}>
                Entrar
              </Button>
              <br />
              <br />
              {errorMessage && <Alert severity="error" sx={{ width: "40vw", marginLeft: "auto", marginRight: "auto" }}>
                {errorMessage}
              </Alert>}
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
