import React from 'react';
import TextField from '@mui/material/TextField';

const FilterTextField = ({ name, label, value, onChange }) => {
  return (
    <TextField
      variant="standard"
      label={label}
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      onClick={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}
    />
  );
};

export default FilterTextField;
