import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import axios from "axios";
import { api_base_url } from 'config';

export const Dashboard = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (localStorage.getItem('access_token') === null) {
      window.location.href = '/login'
    }
    else {
      (async () => {
        try {
          const url = api_base_url + '/site/dashboard/'
          const { data } = await axios.get(url);
          setMessage(data.message);
        } catch (e) {
          console.log('not auth')
        }
      })()
    };
  }, []);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="DASHBOARD" subtitle="Bem vindo!" />
      <h3>Hi {message}</h3>
    </Box>

  )
}

export default Dashboard;
