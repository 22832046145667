import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import CategoryIcon from "@mui/icons-material/Category";
import DiscountIcon from "@mui/icons-material/Discount";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PestControlIcon from "@mui/icons-material/PestControl";
import StoreIcon from "@mui/icons-material/Store";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const navItems = [
  {
    text: "Cupons capturados",
    icon: <PestControlIcon />,
    to: "/crawled-coupon",
  },
  {
    text: "Lojas",
    icon: <StoreIcon />,
    to: "/stores",
  },
  {
    text: "Cupons",
    icon: <DiscountIcon />,
    to: "/coupons",
  },
  {
    text: "Categorias",
    icon: <CategoryIcon />,
    to: "/categories",
  },
  {
    text: "Especiais",
    icon: <AppShortcutIcon />,
    to: "/specials",
  },
  {
    text: "Redes",
    icon: <LeakAddIcon />,
    to: "/networks",
  },
  {
    text: "URLs Quebradas",
    icon: <LinkOffIcon />,
    to: "/broken-urls"
  },
];

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, drawerWidth, isMobile }) => {
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  return (
    <Drawer
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      variant="persistent"
      sx={{
        width: isMobile ? "auto": drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          paddingTop: "64px", // 64px from theme.mixins.toolbar.minHeight
        },
      }}
    >
      <List>
        {navItems.map(({ text, icon, to }) => {
          return (
            <ListItemButton
              key={to}
              onClick={() => {
                setActive(to);
                navigate(to || "/");
              }}
              selected={active.startsWith(to)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
              {active === to && <ListItemIcon sx={{ ml: "auto" }} edge="end" />}
            </ListItemButton>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
