import { orange, yellow } from "@mui/material/colors";
import {  alpha } from '@mui/material/styles';

// mui theme settings
export const themeSettings = (mode) => {
  return {
    typography: {
      fontFamily: [
        "sans-serif",
        "Roboto",
        "Raleway",
      ]
    },
    palette: {
      snoopy: {
        orange: {
          main: orange[50],
          translucent: alpha(orange[50], 0.5),
        },
        yellow: {
          main: yellow[900],
        }
      }
    }
  };
};
